<template>

<nav>
    <a href="#" class="logo">
        <img src="img/logo.png" alt="" class="logo">
    </a>

    <!-- <a v-if="$route.name == 'Home'" href="/login.html" target="_blanck" class="login-btn">
        <span v-if="$store.state.lang == 'en'">LogIn</span>
        <span v-if="$store.state.lang == 'ar'">تسجيل الدخول</span>
    </a> -->

    <!-- <div class="lang">
        <span class="current-lang" v-if="$store.state.lang == 'en'"> En </span>
        <span class="current-lang" v-if="$store.state.lang == 'ar'"> ع </span>

        <div class="lang-menu" :class="{rtl: $store.state.lang === 'ar'}">
            <button 
                class="arabic-btn" 
                :class="{active: $store.state.lang === 'ar'}"
                @click="$store.commit('SET_LANG_AR')"
                >
                عربى
            </button>
            <button 
                class="arabic-btn" 
                :class="{active: $store.state.lang === 'en'}"
                @click="$store.commit('SET_LANG_EN')"
                >
                English
            </button>
        </div>
    </div> -->
</nav>
</template>

<script>
export default {

}
</script>

<style>

</style>