<template>
    <div class="terms">
        <div class="container">
            <p>
    <pre>
Crazy User Agreement
Welcome to crazy (the “Platform” or “APP”). The Platform is provided and controlled by Crazy TECHNOLOGY LIMITED. (“crazy”, “we” or “us”) This Terms of Service ("Terms") was written in English (US). This Terms is the legitimate agreement by and between You and crazy. To the extent any translated version of Terms agreement conflicts against the English version, this English version prevails.

1. Special Notices

1.1 This crazy User Agreement (this “Agreement”) governs your usage of our services, (hereinafter, “Services”) including crazy App, a Audio streaming application and social network developed by us. For the purposes of this Agreement, you and crazy will be jointly referred to as the “Parties” and respectively as a “Party”. 
1.2 By using our Services, or by clicking on "Sign Up" during the registration process, you agree to all terms of this Agreement. We, at our sole discretion, may revise this Agreement from time to time, and the current version will be found at the following link: About us>User Agreement. By continuing to avail our Services, you agree to be bound by the revised Agreement.  
1.3 You may only use our Service if you are 16 years or older, and if you are not subject to statutory age limit to enter into this Agreement according to the applicable laws and regulations in your country. If you are below the aforementioned minimum age, you may only use crazy if your guardian has provided us with valid consent for you to use crazy . You may not falsely claim you have reached the minimum age. 
1.4 You shall be solely responsible for the safekeeping of your crazy account and password. All behaviors and activities conducted through your crazy account will be deemed as your behaviors and activities for which you shall be solely responsible.  

2. Services Content

2.1.We reserve the right to change the content of our Services from time to time, at our discretion, with or without notice.
2.2 Some of the Services provided by crazy require payment. For paid-for Services, crazy will give you explicit notice in advance and you may only access such Services if you agree to and pay the relevant charges. If you choose to decline to pay the relevant charges, crazy shall not be bound to provide such paid-for Services to you.
2.3 crazy needs to perform scheduled or unscheduled repairs and maintenance. If such situations cause an interruption of paid-for Services for a reasonable duration, crazy shall not bear any liability to you and/or to any third parties. However, crazy shall provide notice to you as soon as possible. 
2.4 crazy has the right to suspend, terminate or restrict provision of the Services under this Agreement at any time and is not obligated to bear any liability to you or any third party, if any of the following events occur: 
2.4.1 You are under the minimum age in order to receive crazy services;  
2.4.2 You violate the Terms of Use stipulated in this Agreement; 
2.4.3 You fail to make a payment for using paid-for Services.  
2.5 EXCEPT FOR THE EXPRESS REPRESENTATIONS AND WARRANTIES SET FORTH IN THIS AGREEMENT, crazy MAKES NO WARRANTY IN CONNECTION WITH THE SUBJECT MATTER OF THIS AGREEMENT AND crazy HEREBY DISCLAIMS ANY AND ALL OTHER WARRANTIES, WHETHER STATUTORY, EXPRESS OR IMPLIED, INCLUDING ALL IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND ANY IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR PERFORMANCE, REGARDING SUCH SUBJECT MATTER.  

3. Privacy

You acknowledge that you have read and fully understand our Privacy Policy, which describes how we handle the data you provide to us or generated when you use our Services. If you have any question, please contact us at: support@crazy.live

4. Virtual Items

4.1 You can only buy virtual golds (“Golds”) and virtual gifts (Gifits), send Gifts to others, receive Gifts with monetary value, earn virtual diamonds ("Diamonds").
4.2 The price of the Golds will be displayed at the point of purchase. All charges and payments for Golds will be made in the currency specified at the point of purchase through the relevant payment mechanism. Currency exchange settlements, foreign transaction fees and payment channel fees, if any, are based on your agreement with the applicable payment provider.
4.3 You will be responsible for the payment of any Golds purchased by you. Once your purchase has been completed, your user account will be credited with Golds. Golds can be used to purchase Gifts. Golds cannot be exchanged for cash, or legal tender, or currency of any state, region, or any political entity, or any other form of credit. Golds can only be used on crazy and as part of our Services, and cannot be combined or used in conjunction with other promotions, coupons, discounts or special offers, except those designated by us.
4.4 Except as otherwise set out in this Agreement, all sales of Golds and Gifts are final, and we do not offer refunds for any purchased Golds and Gifts. Golds and Gifts cannot be converted into or exchanged for cash, or be refunded or reimbursed by us for any reason.

5. Terminating Services

You may terminate crazy Services and this Agreement by revoking your crazy account. You may contact us at:support@crazy.live

6. Disclaimers

6.1 You shall be fully responsible for any risks involved in using crazy Services. Any use or reliance on crazy Services will be at your own risk.  
6.2 Under no circumstance does crazy guarantee that the Services will satisfy your requirements, or guarantee that the Services will be uninterrupted. The timeliness, security and accuracy of the Services are also not guaranteed. You acknowledge and agree that the Services is provided by crazy on an “as is” basis. crazy make no representations or warranties of any kind express or implied as to the operation and the providing of such Services or any part thereof. crazy shall not be liable in any way for the quality, timeliness, accuracy or completeness of the Services and shall not be responsible for any consequences which may arise from your use of such Services.
6.3 crazy does not guarantee the accuracy and integrity of any external links that may be accessible by using the Services and/or any external links that have been placed for the convenience of you. crazy shall not be responsible for the content of any linked site or any link contained in a linked site, and crazy shall not be held responsible or liable, directly or indirectly, for any loss or damage in connection with the use of the Services by you. Moreover, crazy shall not bear any responsibility for the content of any web page that you are directed via an external link that is not under the control of crazy . 
6.4 crazy shall not bear any liability for the interruption of or other inadequacies in the Services caused by circumstances of force majeure, or that are otherwise beyond the control of crazy . However, as far as possible, crazy shall reasonably attempt to minimize the resulting losses of and impact upon you. 

7. Other Terms

7.1 This Agreement constitutes the entire agreement of agreed items and other relevant matters between both parties. Other than as stipulated by this Agreement, no other rights are vested in either Party to this Agreement.  
7.2 If any provision of this Agreement is rendered void or unenforceable by competent authorities, in whole or in part, for any reason, the remaining provisions of this Agreement shall remain valid and binding.  
7.3 The headings within this Agreement have been set for the sake of convenience, and shall be disregarded in the interpretation of this Agreement.

8. Governing Law

These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of HK.

9. Contact us

9.1 Email: support@crazychat.app
9.2 WebSite: https://api.crazychat.app
9.3 App: crazy
9.4 Business: Crazy Technology Limited
9.5 Location: ROOM 1602 16/F LUCKY CENTRE NO 165-171 WAN CHAI ROAD WAN CHAI HK

    </pre>
        </p>
        </div>
    </div>
</template>
