<template>
<div class="footer-section">


    <ul class="footer-menu">
        <li>
            <router-link class="footer-link" :to="{name:'Terms'}">
                {{ $store.state.lang == 'en' ? 'Terms of Service' : 'شروط الخدمة' }}
            </router-link>
        </li>
        <li>|</li>
        <li>
            <router-link class="footer-link" :to="{name: 'PrivacyPolicy'}">
                {{ $store.state.lang == 'en' ? 'Privacy Policy' : 'سياسة الخصوصية' }}
            </router-link>
        </li>
        <li>|</li>
        <li>
            <router-link class="footer-link" :to="{name: 'About'}">
                {{ $store.state.lang == 'en' ? 'About Crazy' : 'عن كريزى' }}
            </router-link>
        </li>
        <li>|</li>
        <li>
            <router-link class="footer-link" :to="{name: 'ContactUs'}">
                {{ $store.state.lang == 'en' ? 'Contact Us' : 'تواصل معنا' }}
            </router-link>
        </li>
        <!-- <li>|</li>
        <li>
            <span v-if="$store.state.lang == 'en'" class="footer-link lang" @click="$store.commit('SET_LANG_AR')" >
                عربى
            </span>
            <span v-if="$store.state.lang == 'ar'" class="footer-link lang" @click="$store.commit('SET_LANG_EN')" >
                English
            </span>
        </li> -->
    </ul>
    <p class="copy-right">
        Development by <a href="https://www.codeist.live/" target="_blank"> Codeist </a> &copy; 2022 All Right Reserved
    </p>


</div>
</template>

<script>
export default {
}
</script>

<style>

</style>