import { createStore } from 'vuex'

export default createStore({
    state: {
        lang: 'en',
    },
    mutations: {
        SET_LANG_AR(state) {
            state.lang = 'ar'
        },
        SET_LANG_EN(state) {
            state.lang = 'en'
        }
    },
    actions: {
    },
    getters: {
        directionState(state) {

            let direction = {
                direction: state.lang == 'en' ? 'ltr' : 'rtl'
            }

            return direction;
        }
    },
    modules: {
    }
})
