<template>
<div class="body-section">


    <img :src="'img/title-' + $store.state.lang + '.png'" alt="Title" class="title">
    <img :src="'img/description-' + $store.state.lang + '.png'" alt="Description" class="description">

    <div class="wrapper">
        <h5 class="support-title">
            Support : 
            <a class="support-link" href="mailto:support@crazychat.app">support@crazychat.app</a>
        </h5>
        
    </div>
    <!-- <div class="links-wrapper">
        <a class="btn-link google" href="#">
            <img :src="'img/google-play-' + $store.state.lang + '.png'">
        </a>
        <a class="btn-link apple" href="#">
            <img :src="'img/app-store-' + $store.state.lang + '.png'">
        </a>
        <a class="btn-link apk" href="#">
            <img :src="'img/apk-' + $store.state.lang + '.png'">
        </a>
    </div> -->


</div>
</template>

<script>
export default {

}
</script>

<style>

</style>