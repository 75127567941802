<template>
<div class="home" :style="$store.getters.directionState">

    <BackgroundImages />

    <div class="container">
            
            <NavSection />

            <BodySection />

            <FooterSection />
            
    </div>
</div>
</template>

<script>
import NavSection from '../components/home/NavSection.vue'
import BodySection from '../components/home/BodySection.vue'
import FooterSection from '../components/home/FooterSection.vue'
import BackgroundImages from '../components/home/BackgroundImages.vue'

export default {
    name: 'Home',
    components: { NavSection, BodySection, FooterSection, BackgroundImages },
    data() {
        return {
            bgIndex: 0,
        }
    },
}
</script>
