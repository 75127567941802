<template>
<div>
    <div class="container pt-5">
        <div class="row">
            <div class="col-md-6 m-auto">
                <h3>Contact Us</h3>
                <form action="" @submit.prevent="submitEvent">
                    <div class="form-group">
                        <input v-model="email" type="email" placeholder="Enter Your Email" class="form-control"  >
                    </div>
                    <div class="form-group">
                        <textarea v-model="message" placeholder="Enter Your Message" rows="5" class="form-control" ></textarea>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary d-block w-75 m-auto">Send</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        email: '',
        message: '',
    }),
    methods: {
        submitEvent() {
            if (!this.email) {
                alert('Enter Your Email');
                return ;
            }
            if (!this.message) {
                alert('Enter Your Message');
                return ;
            }
            alert('Your Message Has been sent')
        }
    }
}
</script>

<style>

</style>