<template>
<div class="about">
    <div class="container">
        <div class="header">
            <h1>Crazychat is a voice entertainment community dedicated to global users who enjoy party.</h1>
            <p>You do not need to show your face in Crazychat, you can participate in various social chats and interactive games with your voice, here where you can meet people from thousands of different places, find same-minded people and become friends, here you may start your story. Every moment here can bring you happiness, because Crazychat is an integrated entertainment party APP.</p>

        </div>
        
        <div class="features">
            <!-- <h2 class="title h1">Features</h2> -->
            <div class="row">
                <div class="col-md-4 mb-3">
                    <h3>Contact all the world</h3>
                    <p>We cover global network and let you talk with a lot of interesting people easily.</p>
                </div>

                <div class="col-md-4 mb-3">
                    <h3>Your own voice room</h3>
                    <p>Enjoy the voice chat in your own room and share your room with others.</p>
                </div>

                <div class="col-md-4 mb-3">
                    <h3>Multiple fun activities</h3>
                    <p>Here are many funny activities, such as sing, game.</p>
                </div>

                <div class="col-md-6 mb-3">
                    <h3>Wonderful gifts</h3>
                    <p>Variety of exquisite gifts, luxury sports cars, beautiful avatar frame and other decorations to choose.</p>
                </div>

                <div class="col-md-6 mb-3">
                    <h3>Terminating Services</h3>
                    <p>You may terminate Crazychat Services and this Agreement by revoking your Crazychat account. You may contact us at:support@crazychat.app</p>
                </div>
            </div>
        </div>

        <footer>
            <p>Follow us for the latest news, APP updates and Activity news:</p>
            <p>Facebook: facebook.com/Crazychatchat</p>
            <p>Website: www.crazychat.app</p>
            <p>Dear friends, your comments and suggestions are very welcomed, please email us at: support@crazychat.app</p>
            <p>Business: Crazychat Technology Limited
Location:ROOM 1602 16/F LUCKY CENTRE NO 165-171 WAN CHAI ROAD WAN CHAI HK</p>
        </footer>


    </div>
</div>
</template>
