<template>
<div class="privacy-policy">
    <div class="container">
    <h1 class="title">Welcome to Crazy (the “Platform” or “APP”).</h1>
    <h2>Crazy's Privacy Policy</h2>

    <p>
        The Platform is provided and controlled by Crazy TECHNOLOGY LIMITED.
        (“Crazy”, “we” or “us”) This Terms of Service ("Terms") was written in
        English (US). This Terms is the legitimate agreement by and between You
        and Crazy. To the extent any translated version of Terms agreement
        conflicts against the English version, this English version prevails.
        The Summary
    </p>

    <p>
        You should read this privacy policy because it will tell you exactly how
        your personal data is being looked after by Crazy and it is very
        important for you to know.
    </p>

    <b>Here are the topics that we cover:</b>
    <ol>
        <li>1. What information we collect and how we collect it.</li>
        <li>2. How we use the information and who we share it with.</li>
        <li>3. How you can access and update such information.</li>
        <li>4. How we protect the information we store about you.</li>
    </ol>
    <b>The full legal bit</b>
    <p>
        We know that other people’s use of your personal data is a big concern
        for social network users, so we at Crazy have developed the Policy to
        let you know how we use personal information ('personal data' or
        'personal information'). We suggest you read it in conjunction with our
        Terms and Conditions of Use (the “Terms”). When you access or use Crazy,
        you agree to our Privacy Policy and you consent to our collection,
        storage, use and disclosure of your personal information as we’ve set
        out in this Policy. Crazy reserves the right to change this Policy at
        any time and without notice, so be sure to check this Policy regularly
        to understand how we use your information. By installing, using,
        registering to or otherwise accessing the services, you agree to this
        privacy policy and give an explicit and informed consent to the
        processing of your personal data in accordance with this privacy policy.
        IF YOU DO NOT ACCEPT AND AGREE WITH OUR PRIVACY POLICY THEN YOU MUST NOT
        ACCESS OR USE THE APP.
    </p>
    <b>1. Crazy user's information</b>
    <p>
        To join Crazy you will provide us with information about you to make
        friends easier?such as your nickname, your picture, your gender, your
        date of birth. Because you control your user profile, these details are
        available to you at any time by accessing your “Profile” page, which
        gives you the chance to correct or update your information at any time
        by just logging in to Crazy.
    </p>
    <b> What information does Crazy collect </b>
    <p>
        Once you have created a Profile, we will automatically track certain
        information such as your IP, device locale, timezone. And we also
        collect infomation about WiFi access points as well as other location
        information about your longitude and latitude. We may from time to time
        allow our employees to use the product for testing and user engagement
        purposes. For safety and security and to ensure you have the best
        possible user experience, we require users to verify their accounts
        (because we want to make sure you are not a robot!) and might ask for
        your phone number or Facebook Account. Don’t worry! This is only to make
        sure you are real and breathing! We won't publicize your phone number
        without your consent. When you use our Service, our servers
        automatically record certain log file information, including your web
        request, Internet Protocol ("IP") address, browser type, referring /
        exit pages and URLs, number of clicks and how you interact with links on
        the Service, domain names, landing pages, pages viewed, and other such
        information. The information allows for more accurate reporting and
        improvement of the Service.
    </p>

    <b>How we use your information</b>
    <p>
        In addition to some of the specific uses of information we describe in
        this Privacy Policy,
    </p>
    <b>we may use information that we receive to:</b>

    <ol>
        <li>
        1. help you efficiently access your information after you sign in;
        </li>
        <li>
        2. remember information so you will not have to re-enter it during
        your visit or the next time you visit the Service;
        </li>
        <li>
        3. provide personalized content and information to you and others,
        which could include online ads or other forms of marketing;
        </li>
        <li>
        4. provide, improve, test, and monitor the effectiveness of our
        Service;
        </li>
        <li>5. develop and test new products and features;</li>
        <li>
        6. monitor metrics such as total number of visitors, traffic, and
        demographic patterns;
        </li>
        <li>7. diagnose or fix technology problems;</li>
        <li>
        8. automatically update the APP on your device Crazy or other Users
        may run contests, special offers or other events or activities
        ("Events") on the Service. If you do not want to participate in an
        Event, do not use the particular Metadata (i.e. hashtag or geotag)
        associated with that Event.
        </li>
    </ol>
    <b
        >If I put other information about me on the Crazy, what does Crazy do
        with it?</b
    >
    <p>
        Crazy is a free social Live app, which provides you to voice chat with
        your friends. In the app, you can find new friends, go Live, join a
        Live, send gifts, get profits by receiving gifts.When using the Service
        you should assume that anything you post or submit will be publicly
        viewable and accessible, both by users of the Service and non-Users of
        the Service. The viewers may share your information to other sites, such
        as Facebook, Instagram, WhatsApp, etc. You can also use our
        messaging/charting features which allow you to communicate with other
        users in public and/or privately. We recommend and encourage you (and
        all our users) to think carefully about the information you disclose
        about yourselves. We also do not recommend that you put email addresses,
        URLs, instant messaging details, phone numbers, full names or addresses,
        credit card details, national identity numbers, drivers’ licence details
        and other sensitive information in your Profile which is open to abuse
        and misuse. Please be careful about posting sensitive details about
        yourself on you Profile such as your religious denomination and health
        details. While you may voluntarily provide this information to us when
        you create your Profile, including your sexual preferences and ethnic
        background, there is no requirement to do so. Please remember that
        photographs or any video clips that you post on Crazy may reveal these
        kinds of sensitive personal data. Where you do upload and choose to tell
        us sensitive information about yourself, you are explicitly consenting
        to our processing your information and making this public to other
        users. When you post information about yourself or use the messaging,
        the amount of personal information you share is at your own risk. If you
        post anything that is inconsistent with our Terms of Service we reserve
        the right to terminate your account.<br />
    </p>
    <b
        >Can Crazy collect information about me from other sites I use, such as
        Facebook?</b
    >
    <p>
        You can register with third party accounts on Crazy. Remember that when
        you register with a Crazy partner, you are also giving them personal
        information, so we recommend that you read their privacy policies as
        Crazy does not control how they use their information. If you have have
        registered on one of our partner websites, or you have verified your
        account via a partner website, we may connect your Crazy profile with
        your profile on such partner website. We may do this by way of new
        products and applications which we introduce from time to time,
        including new products powered by our group companies.
    </p>
    <b>Does Crazy collect any other personal information about me?</b>
    <p>
        If you contact our Customer Support team via feedback features in our
        Service or through any other means, we may receive your email address,
        your IP address as well as the information you send to us to help
        resolve your query. When you provide feedbacks to us you automatically
        consent us to receive and use such information. You also consent that we
        are allowed to keep records of our communications with you, including
        any complaints that we receive from you about other users (and from
        other users about you).
    </p>
    <b> Does Crazy use my personal information for any other purpose? </b>
    <p>
        We may use material that you post on the open access areas of Crazy in
        advertising and promotional materials on our partner sites and partner
        products. We believe these uses allow us to improve our site and better
        tailor your online experience to meet your needs.
    </p>
    <b>
        When I put information about myself on Crazy, what do the other users
        see?<br />
    </b>
    <p>
        To ensure you get to meet as many people as possible on Crazy, other
        users get to see all information on your Profile. Any information you
        choose to provide should reflect how much you want other Crazy users to
        know about you.
    </p>
    <b>
        What am I allowed to do with other user’s personal information?<br />
    </b>
    <p>
        Use it to meet new friends and make new contacts! Beware that you may
        not use other users' information for commercial purposes, to spam, to
        harass, to threat, or in any illegal activity. Crazy reserves the right
        to terminate accounts from those who misuse other users' information.
    </p>
    <b>
        What information does Crazy collect about my friends and contacts?<br />
    </b>
    <p>
        With your consent, we may receive and use your contacts list information
        on your phone, and other information you provide us for providing better
        service for you. If you use invitation/share features in our Service, we
        may send your friends and acquaintances invitation messages in your
        name.<br />
    </p>
    <b>
        2. Third parties<br />
        Does Crazy sell my information to other parties?<br />
    </b>
    Not at all. We do not sell or rent out any personal information about you
    to any third party. Crazy discloses aggregated non-personal data for
    marketing and promotional purposes. That means we do not disclose any
    information that could be used to identify you.
    <b>

    Does Crazy disclose my information to other parties?<br />
    </b>
    <p>

    We may share aggregated information with such parties as Facebook that
    includes your personal information (but which doesn’t identify you
    directly), together with other information including log data with third
    parties for industry analysis and demographic profiling. Crazy also wishes
    to maintain a healthy community, and we will cooperate with all third
    parties to enforce their intellectual property or other rights. We will
    also cooperate with law enforcement enquiries from within and/or outside
    your country of residence. This may include disclosing your personal
    information to government or law enforcement agencies, or private parties,
    to protect the rights, property or safety of Crazy and the users of APP,
    or to protect public interest when we have a good faith belief that
    disclosure is required by law or when we, in our discretion, believe that
    disclosure is necessary to protect our legal rights, or those of third
    parties and/or to comply with a judicial proceeding, court order, or legal
    process served on us. In such cases, we may raise or waive any legal
    objection or right available to us.
    </p>
    <b>3. Data storage</b>
    <p>

    Crazy is a global social application operating through servers located in
    a number of countries around the world, including the United States. If
    you live in a country with data protection laws, the storage of your
    personal data may not provide you with the same protections as you enjoy
    in your country of residence. By submitting your personal information, or
    by choosing to upgrade the services you use, or by making use of the
    applications available on Crazy, you agree to the transfer of your
    personal information to, and storage and processing of your personal
    information in, any such countries and destinations.
    </p>
    <b>
        4. Security
        <br /> How does Crazy protect my personal information?<br />
    </b>
    <p>

    Crazy has implemented reasonable and appropriate security measures to
    protect and prevent the loss, misuse, and alteration of the information
    under our control, including your personal information. Crazy uses
    reasonable security measures to safeguard the confidentiality of your
    personal information such as secured servers using firewalls. Our
    technical experts at Crazy work hard to ensure your secure use of our
    site. While we take reasonable precautions against possible security
    breaches of our website, member database and records, no website or
    Internet transmission is completely secure and we cannot guarantee that
    unauthorised access, hacking, data loss, or other breaches will never
    occur. We urge you to take steps to keep your personal information safe
    (including your password) and to log out of your account after use. We
    cannot guarantee the security of your personal data while it is being
    transmitted to our site. Any transmission is at your own risk. Once we
    have received your information we have procedures and security features in
    place to try to prevent unauthorised access.
    </p>
    <b>How do I help keep my information secure?</b>
    <p>

    You may not disclose your password to any third parties or share it with
    any third parties. If you lose your password or give it out, your personal
    information may be compromised. If that happens, please report it to our
    customer service via Feedback. Crazy cannot be held responsible for your
    failure to keep your password secure and failure to do so may violate our
    Terms of Service.
    </p>
    <b>5. Your rights</b>
    <p>
    You can review and revise your profile information at any time. Once you
    register, you will be able to review and change much of your personal
    information.
    </p>
    <b>
        6. Miscellaneous
        <br />Copyright Notice<br />
    </b>
    <p>

    Copyright©2022 Crazy. All rights are reserved. All materials in APP are
    the property of Crazy and are protected by the copyright laws all over the
    world. You are authorized to use Crazy and its contents solely for your
    personal, non-commercial purpose unless receiving specific written
    permission from Crazy. Trademarks<br />
    The trademarks and logos displayed on this site ("Trademarks") are the
    property of Crazy and their respective owners. You are not permitted to
    use these Trademarks without the prior written consent of Crazy or the
    respective owners. Disclaimer<br />
    Crazy provide all materials in "as is" basis, and hereby disclaims any
    representations or warranties (expressed or implied) of any kind,
    including without limitation, any warranties of merchantability, fitness
    for a particular purpose, non-infringement or other violation of rights
    arising from the use, accuracy or reliability of materials of the Service
    or any sites linked out. You acknowledge any information transmitted may
    be intercepted. Crazy does not warrant that its service or the servers
    which make the Service available or electronic communications transmitted
    are free from viruses, worms, horses and other items of a destructive
    nature. Links to Third Party Sites<br />
    There might be links direct you to third party web sites, these links are
    provided solely as a convenience for you. Crazy does not endorse or make
    any representations or warranties about the contents, products, services
    available, or the security at such third party web sites. Safe Harbor
    Provision<br />
    The information you obtain while using Crazy may contain certain
    forward-looking statements, plans and objectives with respect to the
    financial condition, operation and business of Crazy and/or any of its
    affiliate.The forward-looking statements are only predictions and are not
    guarantees of future performance. The forward-looking statements shall be
    subject to risks, uncertainties, and other uncontrollable factors include,
    but are not limited to, levels of consumer and business spending in major
    economies, the levels of marketing and promotional expenditures by Crazy
    and its competitors, raw materials and employee costs, changes in future
    exchange and interest rates, changes in tax rates and technical changes,
    which could cause actual results materially different from those expressed
    or forecasted in the forward-looking statements. Limitation of
    Liability<br />
    </p>
    <b>

    Crazy WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING OUT OF OR
    RELATING TO THE USE OR THE INABILITY TO USE APP, ITS CONTENT OR
    LINKS,INCLUDING BUT NOT LIMITED TO DAMAGES CAUSED BY OR RELATED TO ERRORS,
    OMISSIONS, INTERRUPTIONS, DEFECTS, DELAY IN OPERATION OR TRANSMISSION,
    VIRUS, APPLICATION FAILURE, AND ALL OTHER DIRECT, INDIRECT, SPECIAL,
    INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES EVEN IF Crazy HAS BEEN
    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. Crazy'S TOTAL LIABILITY TO YOU
    FOR ALL LOSSES, DAMAGES, AND CAUSES OF ACTION,INCLUDING BUT NOT LIMITED TO
    THOSE BASED ON CONTRACT, TORT OR OTHERWISE, ARISING OUT OF YOUR USE OF
    APP, ITS CONTENT OR LINKS, SHALL NOT EXCEED THE AMOUNT OF USD 500 DOLLARS.
    Governing Law<br />
    These Terms of Service and any separate agreements whereby we provide you
    Services shall be governed by and construed in accordance with the laws of
    HK Prohibited Usage<br />
    </b>
    <p>

    You will not use the Service for any purpose that is unlawful or not
    allowed by Crazy. You may not use our Service or its contents in any
    manner which could damage, disable,overburden, or interfere with any
    person to use this Service. You may not attempt to gain unauthorized
    access to the Service other accounts, computer systems or networks
    connection through hacking, password mining or any other means. You may
    not use or attempt to use this Service through any means unanticipated or
    intentionally made available by Crazy. IP Protection<br />
    <br />Crazy respects intellectual property laws and has adopted the
    following policy toward intellectual property infringement on its
    products/services. Reporting Instances of Intellectual Property Violations
    If you believe that any materials or contents in Crazy‘s APP infringe
    copyrights, trademarks, patents, or trade secrets (collectively “IP
    Right”), please send a notice of IP Rights infringement to Crazy's agent
    with a physical signature of the owner of the IP Right that has been
    allegedly infringed. 
    
    <br />1. Identification of the material that is
    claimed to be infringing, including its location on the Crazy network,
    with sufficient detail so that Crazy is capable of finding the material
    and verifying its existence. Please address your intention to remove the
    alleged infringed materials. 
    <br />2. Contact information, including your
    name, address, telephone number, and email address. 
    <br />3. A statement
    by you that you have a good faith belief that the disputed use is not
    authorized by the IP Right owner, its agent or law. 
    <br />4. A statement
    by you that the above information in your Notice is accurate and that,
    under penalty of perjury, you are the IP Right owner or authorized to act
    on the IP Right owner's Behalf. 
    <br />5. Agree to undertake compensation
    responsibility to Crazy for any false and incorrect information addressed
    in above statements. 
    <br />Objections to the Reporting<br />
    <br />If you have received a IP Right warning from Crazy, please contact
    the below Designated Agent for further discussions and clarifications.
    </p>
    <b>

    Crazy will manage the issue in accordance with the Policy/terms and
    conditions specified by Crazy. Designated Agent: support@crazychat.app
    <br />7. Contact us <br />1. Email: support@crazychat.app <br />2.
    WebSite: https://www.crazychat.app <br />3. App: Crazy <br />4. Business:
    Crazy Technology Limited <br />5. Location: ROOM 1602 16/F LUCKY CENTRE NO
    165-171 WAN CHAI ROAD WAN CHAI HK
    </b>
    </div>
</div>
</template>
