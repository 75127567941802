<template>
<div class="background-images">


    <transition name="fade">
    <img v-if="bgIndex == 0"      src="img/cover-1.jpg" alt="" class="scaling">
    <img v-else-if="bgIndex == 1" src="img/cover-2.jpg" alt="" class="scaling">
    <img v-else-if="bgIndex == 2" src="img/cover-3.jpg" alt="" class="scaling">
    <img v-else-if="bgIndex == 3" src="img/cover-4.jpg" alt="" class="scaling">
    <img v-else-if="bgIndex == 4" src="img/cover-5.jpg" alt="" class="scaling">
    <img v-else-if="bgIndex == 5" src="img/cover-6.jpg" alt="" class="scaling">
    <img  v-else src="img/cover-7.jpg" alt="" class="scaling bg-3">
    </transition>
    <div class="overlay"></div>


</div>
</template>

<script>
export default {
    data() {
        return {
            bgIndex: 0,
        }
    },
    mounted() {
        
        setInterval(() => {
            if (this.bgIndex < 6) {

                this.bgIndex++

            } else {
                this.bgIndex = 0;
            }

        }, 5000)
    }
}
</script>

<style>

.fade-leave-to,
.fade-enter-from {
    opacity: 0
}

.fade-enter-active {
    transition: all 2s linear;
}

.fade-leave-active {
    transition: all 2s linear;
    position: absolute;
}
</style>